import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

import locales from "../../constants";

const Naturlig = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Naturlig Flex",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/naturlig_og.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #2884CA, #2895E8, #4BCFF7, #36B5EE, #2888D1 )",
        icons: "#fff",
        navClass: "piotrus_pan__nav",
        ogImage: require("../../assets/img/portfolio/naturlig_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/naturlig/",
      }}
    >
      <PortfolioHeader name="naturlig" height="260" />
      <div className="naturlig">
        <section
          className="container-fluid portfolio-intro naturlig-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="inner">
                <h1>NaturligFlex</h1>
                <ul>
                  <li>Projekt loga</li>
                  <li>Projekt opakowania</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner inner--divider">
                <p>
                  Dla naszego zagranicznego klienta zaprojektowaliśmy opakowanie
                  na suplement diety. Projekt zakładał stworzenie opakowania z
                  wykorzystaniem różnych technik uszlachetniający druk oraz logo
                  produktu wraz z księgą znaku. Łącznie projekt został
                  zaadaptowany na dwa różne opakowania jednostkowe wraz z
                  blistrami wewnątrz nich oraz na opakowanie zbiorcze.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid naturlig-section-3">
          <div className="inner">
            <h2>Logo</h2>
            <p>
              Forma graficzna loga została zbudowana z wykorzystaniem samej
              nazwy produktu na siatce modułowej. Barwa niebieska, która
              dominuje w znaku, została również główną barwą używaną w dalszej
              pracy projektowej. Barwa ta została dodatkowo uzupełniona o
              kontrastowe kolory, z których stworzony został gradient użyty do
              wyróżnienia i zaakcentowania elementu znaku. Księga znaku
              stworzona dla poprawnego wykorzystywania znaku została dostarczona
              w dwóch wersjach językowych.
            </p>
          </div>
          <div className="logo-section">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/naturlig_logo_colors.png")}
              alt=""
            />
            <div className="row">
              <div className="col-lg-5 col_1">
                <div className="img_wrapper text-center">
                  <img
                    className="img-fluid"
                    src={require("../../assets/img/portfolio/naturlig_logo.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 col_2">
                <div className="row">
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>Blue CMYK</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 74 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 22 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>Violet CMYK</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 20 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 85 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>Orange CMYK</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 89 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 98 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 0 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>RGB:</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 39 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 157 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 217 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>RGB:</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 201 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 65 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 145 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <TrackVisibility once offset={300} className="col-sm-4">
                    {({ isVisible }) => (
                      <>
                        <p>RGB:</p>
                        <h5>
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 230 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 54 : 0}
                          />
                          <CountUp
                            start={0}
                            duration={3}
                            end={isVisible ? 22 : 0}
                          />
                        </h5>
                      </>
                    )}
                  </TrackVisibility>
                  <div className="col-sm-4">
                    <p>HEX</p>
                    <h5>279dd8</h5>
                  </div>
                  <div className="col-sm-4">
                    <p>HEX</p>
                    <h5>c94191</h5>
                  </div>
                  <div className="col-sm-4">
                    <p>HEX</p>
                    <h5>e63616</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="naturlig-section-4">
          <div className="content">
            <img
              src={require("../../assets/img/portfolio/naturlig_main_1.png")}
              alt=""
            />
            <div className="inner">
              <h2>Opakowanie</h2>
              <p>
                Projekt zakładał przedstawienie obszaru zdrowia, jaki produkt
                wspomaga oraz jego właściwości w czystej, ale wyrazistej formie.
                Opakowanie jednostkowe występowało w dwóch rozmiarach, przy
                produkcji których zostały wykorzystane takie techniki jak
                tłoczenie, lakier UV czy hot stamping.
              </p>
            </div>
            <img
              className="box"
              src={require("../../assets/img/portfolio/naturlig_main_2.png")}
              alt=""
            />
          </div>
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Naturlig;
